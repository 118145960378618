import React from "react"

// import Galery from '../../components/Galery';
// import ProductsApi from "../../components/ProductsApi";
import BookList from "../../components/BookList";

function Books() {
  return (
      <>  
      <BookList/>
      {/* SearchBook */}
      {/* <Galery /> */}
      {/* Galery */}
      {/* <Galery /> */}
      </>
  );
}

export default Books;

  